/* eslint-disable id-match */
/* eslint-disable no-inline-comments */
/* eslint-disable line-comment-position */
import {event} from '../gtag';

const numberOrZero = function (value) {
  return parseFloat(value) || 0;
};

// eslint-disable-next-line complexity, arrow-body-style
const getFFFDimensions = (data, action) => {
  const fffActions = [
    'BF',
    action,
    data.bfSource || '',
    numberOrZero(data.position),
    '', // BF has no celebrity
    data.mainProductId || '',
    data.relatedProductId || '',
    data.category || '',
    '', // BF has no trends
    '', // BF has no affiliate
    '' // BF has no depth
  ].join('^').replace(/\^+$/, '');
  const fffLayout = [
    'inline',
    data.bfSource || '',
    data.articleId || ''].join('^').replace(/\^+$/, '');

  return {
    fff_actions: fffActions,
    fff_layout: fffLayout,
    fffActions,
    fffLayout
  };
};

const createHandler = (category, action, nonInteraction) => (_, data) => {
  event(action, {
    event_category: category,
    non_interaction: Boolean(nonInteraction),
    ...getFFFDimensions(data, action)
  });
};

export const beautyFinderCarouselHandlers = {
  BF_BUY_NOW_CAROUSEL_CLICKED: createHandler('bf', 'alternative product buynow clicked'),
  BF_BUY_NOW_HEADER_CLICKED: createHandler('bf', 'main product buynow click'),
  BF_CAROUSEL_IN_VIEW: createHandler('bf', 'product view', true),
  BF_CAROUSEL_ROTATE_CLICKED: createHandler('bf', 'carousel use'),
  BF_IMPRESSION: createHandler('bf', 'product impression', true),
  BF_SHOW_MORE_CLICKED: createHandler('bf', 'showmore click')
};
