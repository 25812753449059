import {event} from '../gtag';

const createHandler = (category, action) => (_, data) => {
  const {linkText} = data;

  event(action, {
    event_category: category,
    event_label: linkText,
    link_text: linkText,
    linkText,
    non_interaction: false
  });
};

export const timAnchorHandler = {
  TIM_ANCHOR_LINK_CLICKED: createHandler('decisionary anchor', 'click')
};
