/* eslint-disable id-match */
import {event} from '../gtag';

const createHandler = (action, forcedSuffix) => (_, data) => {
  const params = {
    event_category: data.contentType,
    gallery_image_action: data.galleryTitle,
    gallery_image_id: data.threadId,
    gallery_image_swipe_count: data.galleryId,
    galleryImageAction: data.galleryTitle,
    galleryImageId: data.threadId,
    galleryImageSwipeCount: data.galleryId,
    non_interaction: false
  };

  if (data.contentType === 'image') {
    params.image_views = 1;
    params.imageViewIncrease = 1;
  }

  const contentType = data.contentType === 'videoimage' ? 'video_image' : data.contentType;

  event(action, params, `gallery_${contentType}_${forcedSuffix || action}`);
};

export const galleryHandlers = {
  GALLERY_IMAGE_VIEWED: createHandler('view', null),
  GALLERY_OPENED: createHandler('start view', 'view_start')
};
