import ted from '../ted';

const eventCounts = {};

const createHandler = (category, action) => () => {
  if (!eventCounts[action]) {
    eventCounts[action] = 0;
  }

  eventCounts[action]++;

  ted.tedEvent('page_event', {
    action: 'set',
    name: `${category}_${action}`,
    value: eventCounts[action]
  });
};

const paywall = {
  PAYWALL_ACCOUNT_LINK_COMPLETE: createHandler('paywall', 'account_link_complete'),
  PAYWALL_ACCOUNT_LINK_HELP: createHandler('paywall', 'account_link_help'),
  PAYWALL_ACCOUNT_LINK_PROMPT: createHandler('paywall', 'account_link_prompt'),
  PAYWALL_ACCOUNT_LINK_RESEND: createHandler('paywall', 'account_link_resend'),
  PAYWALL_CONVERSION: createHandler('paywall', 'conversion'),
  PAYWALL_CONVERSION_BANNER: createHandler('paywall', 'conversion'),
  PAYWALL_CONVERSION_PROFILE: createHandler('paywall', 'conversion'),
  PAYWALL_IMPRESSION: createHandler('paywall', 'impression'),
  PAYWALL_LOGIN_CLICK: createHandler('paywall', 'login_click'),
  PAYWALL_MASTHEAD_SIGNUP_CLICK: createHandler('paywall', 'signup_masthead'),
  PAYWALL_PROFILE_SIGNUP_CLICK: createHandler('paywall', 'signup_profile'),
  PAYWALL_RESTORE_PURCHASE_CLICK: createHandler('paywall', 'restore_purchase_click'),
  PAYWALL_SIGN_UP: createHandler('paywall', 'signup_article'),
  PAYWALL_VIEWABLE: createHandler('paywall', 'viewable'),
  PAYWALL_ZEPHR_FAIL_OPEN: createHandler('paywall', 'zephr_fail_open')
};

export default paywall;
