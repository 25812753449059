/* eslint-disable id-match */
import {event} from '../gtag';

const numberOrZero = function (value) {
  return parseFloat(value) || 0;
};

// eslint-disable-next-line arrow-body-style
const getArticleIdOrHub = (data) => {
  return data && data.articleId ? data.articleId : 'fff-hub';
};

// eslint-disable-next-line complexity
const getFFFDimensions = (data, action) => {
  const articleId = getArticleIdOrHub(data.pageMetaData);
  const fffActions = [
    'FFF',
    action,
    data.fffSource || '',
    numberOrZero(data.position),
    data.celebrity || '',
    data.mainProduct || '',
    data.relatedProduct || data.accessoryProduct || '',
    data.items || '',
    data.trends || '',
    data.affiliate || '',
    data.depth || ''
  ].join('^');
  const fffLayout = [
    data.fffType || '',
    data.fffSource || '',
    articleId || ''].join('^');

  return {
    fff_actions: fffActions,
    fff_layout: fffLayout,
    fffActions,
    fffLayout
  };
};

const createHandler = (category, action) => (_, data) => {
  event(action, {
    event_category: category,
    ...getFFFDimensions(data, action)
  });
};

const handleSocialClick = (_, data, nonInteraction) => {
  const action = 'social share';

  event(action, {
    event_category: 'fff',
    event_label: data.socialSite,
    non_interaction: Boolean(nonInteraction),
    social_network: data.socialSite,
    socialNetwork: data.socialSite,
    ...getFFFDimensions(data, action)
  });
};

export const fffHandlers = {
  FFF_BUY_ACCESSORY_CLICKED: createHandler('fff', 'accessory product click'),
  FFF_BUY_MAIN_CLICKED: createHandler('fff', 'main product click'),
  FFF_BUY_RELATED_CLICKED: createHandler('fff', 'related product click'),
  FFF_BUY_TRENDING_CLICKED: createHandler('fff', 'trending product click'),
  FFF_CAROUSEL_CLICKED: createHandler('fff', 'carousel click'),
  FFF_CONTENT_VIEWED: createHandler('fff', 'product view', true),
  FFF_HUB_SEARCHED: createHandler('fff', 'search'),
  FFF_LINK_TO_AFFILIATE_CLICKED: createHandler('fff', 'affiliate link click'),
  FFF_LINK_TO_ARTICLE_CLICKED: createHandler('fff', 'click to article'),
  FFF_LINK_TO_HUB_CLICKED: createHandler('fff', 'hub click'),
  FFF_SOCIAL_CLICKED: handleSocialClick
};
