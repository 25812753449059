/* eslint-disable complexity */
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {setToStorage} from '../storage';
import {getMailplusChannelUrl, getMastheadPurchaseUrl} from '../api';

export const initUkHomeMasthead = (mastheadEl) => {
  const {pageType, channel, subchannel} = getPageCriteria();

  if (
    pageType !== 'channel' ||
    channel !== subchannel ||
    channel !== 'home' ||
    location.pathname.startsWith('/registration')
  ) {
    return;
  }

  const isSubscriber = window.getIsPaywallSubscriber && window.getIsPaywallSubscriber();

  if (isSubscriber) {
    const existingLink = document.querySelector('[data-paywall-home-masthead]');

    if (existingLink) {
      existingLink.remove();
      mastheadEl.style.backgroundImage = '';
    }

    return;
  }

  const href = getMastheadPurchaseUrl();
  const linkRel = null;
  const linkTarget = null;

  const link = document.querySelector('[data-paywall-home-masthead]') || document.createElement('a');

  link.href = href;
  link.dataset.paywallHomeMasthead = true;

  if (linkTarget) {
    link.target = linkTarget;
  }

  if (linkRel) {
    link.rel = linkRel;
  }

  link.addEventListener('click', () => {
    pageEvents.publish(pageEvents.PAYWALL_MASTHEAD_SIGNUP_CLICK);
    setToStorage('return-url', `${location.origin}${getMailplusChannelUrl()}`);
    setToStorage('purchase-meta', {
      referringModule: 'masthead',
      referringPageType: 'home'
    });
  });

  mastheadEl.appendChild(link);
};
