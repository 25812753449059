/* eslint-disable id-match */
import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {label, value, modulePixelDepth} = data || {};

  event(action, {
    event_category: category,
    event_label: label,
    module_pixel_depth: modulePixelDepth,
    modulePixelDepth,
    non_interaction: Boolean(nonInteraction),
    value
  });
};

export const commerceXpHandlers = {
  COMMERCE_XP_CLICK: createHandler('commercexp', 'click'),
  COMMERCE_XP_IMPRESSION: createHandler('commercexp', 'impression', true),
  COMMERCE_XP_VIEW_MORE: createHandler('commercexp', 'view more'),
  COMMERCE_XP_VIEWABLE: createHandler('commercexp', 'view', true)
};
