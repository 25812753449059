/* eslint-disable id-match */
import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  event(action, {
    event_category: category,
    module_name: data && data.moduleName || undefined,
    moduleName: data && data.moduleName || undefined,
    non_interaction: Boolean(nonInteraction)
  });
};

export const genericXpModuleHandler = {
  MODULE_IMPRESSION: createHandler('module', 'impression', true),
  MODULE_INTERACT: createHandler('module', 'interact'),
  MODULE_VIEW: createHandler('module', 'view', true)
};
