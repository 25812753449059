import {logger} from '@mol-fe/mol-fe-client-logger';
import {later} from '@mol-fe/mol-fe-async';
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {initDesktop} from './desktop';
import {initMobile} from './mobile';
import {getIsTim, getIsTimInvestingHeader, getPaywallPurchaseUrl} from './api';

export const init = async () => {
  try {
    window.getPaywallPurchaseUrl = getPaywallPurchaseUrl;

    if (getIsTimInvestingHeader()) {
      await initDesktop();
      document.body.classList.add('mailplus-tim');

      return;
    }

    await later('PAGE_METADATA_SET');

    const {renderPlatform} = getPageMetadata();

    if (renderPlatform === 'desktop') {
      await initDesktop();
    } else if (renderPlatform === 'mobile') {
      await initMobile();
    }

    if (getIsTim()) {
      await later('DOM_READY');
      document.body.classList.add('mailplus-tim');
    }
  } catch (error) {
    logger.error('Error initialising paywall masthead', error);
  }
};

init().catch(() => {});
