/**
 * Represents a wrapper around the browser's local storage.
 * @class
 */
export class LocalStorage {
  /**
   * Creates a new instance of the LocalStorage class.
   *
   * @param {string} namespace - The namespace for the local storage object.
   * @memberof LocalStorage
   * @class
   */
  constructor (namespace) {
    this.namespace = namespace;

    this.getItem = this.getItem.bind(this);
    this.setItem = this.setItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.clear = this.clear.bind(this);
  }

  /**
   * Gets the value of the specified key from the local storage.
   *
   * @param {string} key - The key to get the value for.
   * @returns {any} - The value of the specified key or null if not found.
   * @memberof LocalStorage
   */
  getItem (key) {
    try {
      if (!('localStorage' in window)) {
        return null;
      }

      const rawValue = window.localStorage.getItem(`${this.namespace}${key}`);

      if (rawValue) {
        return JSON.parse(rawValue);
      }
    } catch (error) {
      // Ignore failures
    }

    return null;
  }

  /**
   * Sets the value of the specified key in the local storage.
   *
   * @param {string} key - The key to set the value for.
   * @param {any} value - The value to set.
   * @memberof LocalStorage
   */
  setItem (key, value) {
    try {
      if (!('localStorage' in window)) {
        return;
      }

      window.localStorage.setItem(`${this.namespace}${key}`, JSON.stringify(value));
    } catch (error) {
      // Ignore
    }
  }

  /**
   * Removes the specified key from the local storage.
   *
   * @param {string} key - The key to remove.
   * @memberof LocalStorage
   */
  removeItem (key) {
    try {
      if (!('localStorage' in window)) {
        return;
      }

      window.localStorage.removeItem(`${this.namespace}${key}`);
    } catch (error) {
      // Ignore
    }
  }

  /**
   * Clears all the keys from the local storage.
   *
   * @memberof LocalStorage
   */
  clear () {
    try {
      if (!('localStorage' in window)) {
        return;
      }

      Object.keys(window.localStorage)
        .filter((key) => key.startsWith(this.namespace))
        .forEach((key) => window.localStorage.removeItem(key));
    } catch (error) {
      // Ignore
    }
  }
}

/**
 * Creates a LocalStorage instance with the specified namespace.
 *
 * @param {string} namespace - The namespace for the local storage object.
 * @returns {LocalStorage} - The LocalStorage instance with the specified namespace.
 */
export const createLocalStorage = (namespace) => new LocalStorage(namespace);
