/* eslint-disable lines-around-comment, id-match */
export const UA_ID = 'UA-3639451-11';
export const GA4_ID = 'G-C9F47K6NW6';
export const UA_ID_SNAPCHAT = 'UA-3639451-19';
export const GA4_ID_SNAPCHAT = 'G-K7GKTZV547';
export const GA4_NEW_ID = 'G-GQE6MT7DLZ';
export const GTAG_SCRIPT_URL = `https://www.googletagmanager.com/gtag/js?id=${UA_ID}&l=molFeDataLayer`;
export const FBIA_FORCED_REFERRER = 'http://fbia.facebook.com/';
export const CUSTOM_DIMENSION_MAP = {
  dimension1: 'rendered_platform',
  dimension2: 'logged_in_status',
  dimension3: 'author_name',
  dimension4: 'content_type',
  dimension5: 'article_id',
  dimension6: 'url_channel',
  dimension7: 'article_title',
  dimension8: 'article_published_date',
  dimension9: 'domain',
  dimension10: 'sponsor_name',
  dimension11: 'articleAge',
  dimension12: 'wordCount',
  dimension13: 'article_published_hour',
  dimension14: 'url_subchannel_level_1',
  dimension15: 'url_subchannel_level_2',
  dimension16: 'url_subchannel_level_3',
  dimension17: 'gdprStatus',
  dimension18: 'gallery_image_action',
  dimension19: 'gallery_image_swipe_count',
  dimension20: 'gallery_image_id',
  dimension21: 'user_agent',
  dimension22: 'page_url',
  dimension23: 'internal_search_terms',
  dimension24: 'referrer',
  dimension25: 'ico_internal_hit',
  dimension26: 'icoInternalSession',
  dimension27: 'video_duration',
  dimension28: 'video_player_version',
  dimension29: 'video_channel_article_id',
  dimension30: 'video_player_type',
  dimension31: 'video_module',
  dimension32: 'video_ads_enabled',
  dimension33: 'video_internal_id',
  dimension34: 'video_title',
  dimension35: 'video_external_id',
  dimension36: 'user_local_server_date',
  // dimension37: 'pageContainsVideoPlayerTypes',
  dimension38: 'client_id',
  dimension39: 'partner_site',
  dimension40: 'ito_external_hit',
  dimension41: 'itoExternalSession',
  dimension42: 'autoRefreshStatus',
  // dimension43: '__UNUSED__percentOfPagePrevious',
  // dimension44: 'previousPagename',
  dimension45: 'widget_push_notification_status',
  dimension46: 'referringUrl',
  dimension47: 'widget_push_prompt_criteria',
  dimension48: 'push_notification_campaign_id',
  dimension49: 'push_notification_status',
  dimension50: 'bundle_version',
  dimension51: 'adBlockerEnabled',
  dimension52: 'user_login_details',
  dimension53: 'mobile_home_preference',
  dimension54: 'module_tracking',
  dimension55: 'fff_actions',
  dimension56: 'fff_layout',
  dimension57: 'dmtv_episode_id',
  dimension58: 'dmtv_air_date',
  // dimension59: 'moduleTypesTopNav',
  // dimension60: 'moduleTypesBody',
  dimension61: 'article_features',
  // dimension62: 'moduleTypesFooter',
  // dimension63: 'article_finding_method', *** previously disabled prior to MOL-29140 ***
  // dimension64: 'brandDomain',
  // dimension65: 'destinationUrl',
  // dimension66: 'parentArticleTitle',
  dimension67: 'article_tags',
  dimension93: 'swipe_funnel_status',
  // dimension94: 'apple_private_relay',
  // dimension95: 'adFreeUserStatus',
  // dimension96: 'adFreeVersionStatus',
  // dimension97: 'adFreeDeviceNumber',
  dimension98: 'referringDomain',
  dimension100: 'visibility_state',
  dimension101: 'multivariant_experiments',
  dimension111: 'ads_mvt_test_scenario',
  dimension112: 'openweb_enabled',
  dimension113: 'pull_notification_campaign_id',
  dimension114: 'mobile_home_tab_choice',
  dimension115: 'module_name',
  dimension116: 'module_click_list_position',
  dimension117: 'mostFrequentMobileHomeChoice',
  dimension118: 'registration_id',
  dimension121: 'module_pixel_depth',
  dimension122: 'mobile_home_tab_active',
  dimension133: 'puzzle_user_type',
  dimension142: 'poll_id',
  dimension143: 'poll_name',
  dimension144: 'referring_channel',
  dimension145: 'article_status',
  dimension147: 'referring_page_type',
  dimension148: 'user_type',
  dimension149: 'subscription_entitlements',
  dimension150: 'referring_module',
  dimension151: 'previous_page_rendered_platform',
  dimension152: 'paywall_variant',
  dimension154: 'chrome_sandbox_label',
  dimension155: 'flow_variant',

  // ...
  metric1: 'internal_searches',
  metric2: 'article_views',
  metric3: 'auto_refreshes',
  metric4: 'image_views',
  metric5: 'video_views',
  metric7: 'video_25_views',
  metric8: 'video_50_views',
  metric9: 'video_completes',
  metric10: 'video_advert_starts',
  metric11: 'social_share_clicks',
  // ...
  // metric20: 'timeSpentActiveChannel',
  // metric21: 'timeSpentActiveArticle',
  // metric22: 'activitySittingsChannel',
  // metric23: 'activitySittingsArticle',
  metric31: 'paywall_article_views',
  metric32: 'free_article_views',
  metric33: 'has_been_paywalled_article_views',
  metric34: 'is_paywalled_for_others_article_views',
  metric35: 'paywall_impressions',
  metric36: 'paywall_views',
  metric37: 'paywall_clicks',
  metric38: 'paywall_conversions',
  metric44: 'account_conversions',
  metric45: 'banner_conversions'
};
