import {event} from '../gtag';

const createHandler = (category, action, type, nonInteraction) => (_, data) => {
  event(action, {
    event_category: category,
    event_label: data && data.newsletterName || type,
    non_interaction: Boolean(nonInteraction),
    type_of_newsletter: data && data.newsletterName || type,
    typeofnewsletter: data && data.newsletterName || type
  });
};

export const newsletterHandlers = {
  NEWSLETTER_ERROR: createHandler('newsletter', 'submit error', 'generic newsletter'),
  NEWSLETTER_POPUP_SHOW: createHandler('newsletter', 'impression', 'newsletter popup', true),
  NEWSLETTER_POPUP_SUBMIT: createHandler('newsletter', 'submit success', 'newsletter popup'),
  NEWSLETTER_PRIVACY_CLICK: createHandler('newsletter', 'privacy click', 'generic newsletter'),
  NEWSLETTER_SHOW: createHandler('newsletter', 'impression', 'generic newsletter', true),
  NEWSLETTER_SUBMIT: createHandler('newsletter', 'submit success', 'generic newsletter'),
  SAFARI_NEWSLETTER_SHOW: createHandler('newsletter', 'impression', 'safari popup', true),
  SAFARI_NEWSLETTER_SUBMIT: createHandler('newsletter', 'submit success', 'safari popup')
};
