import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {urlClicked, articlePosition} = data;

  event(action, {
    article_position: articlePosition,
    articlePosition,
    event_category: category,
    non_interaction: Boolean(nonInteraction),
    url_clicked: urlClicked,
    urlClicked
  });
};

export const articleRecommendationHandlers = {
  ARTICLE_RECOMMENDATION_XP_CLICK: createHandler('article recommendation module', 'click'),
  ARTICLE_RECOMMENDATION_XP_IMPRESSION: createHandler('article recommendation module', 'impression', true),
  ARTICLE_RECOMMENDATION_XP_VIEWABLE: createHandler('article recommendation module', 'view', true)
};
