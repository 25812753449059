/* eslint-disable id-match */
import {event} from '../gtag';

const createHandler = (category, ga4EventName) => (_, data) => {
  let eventProperties = {
    event_category: category,
    event_label: data.searchTerm,
    non_interaction: false,
    search_term: data.searchTerm,
    searchTerm: data.searchTerm
  };

  if (category === 'site search') {
    eventProperties = {
      ...eventProperties,
      internal_search_terms: data.searchTerm,
      internal_searches: 1,
      internalSearchIncrease: 1,
      internalSearchTerms: data.searchTerm
    };
  }

  event(category, eventProperties, ga4EventName);
};

export const searchHandlers = {
  SITE_SEARCH: createHandler('site search', 'site_search'),
  WEB_SEARCH: createHandler('web search', 'web_search')
};
