/* eslint-disable id-match */
import {getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {setConfig} from '@mol-fe/mol-fe-components';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {loadScript} from '@mol-fe/mol-fe-dom-helpers';
import {CUSTOM_DIMENSION_MAP, UA_ID, GTAG_SCRIPT_URL, GA4_ID, GA4_ID_SNAPCHAT, UA_ID_SNAPCHAT, GA4_NEW_ID} from './constants';
import {getConfigProps} from './meta/index';
import {toLowerCase} from './meta/utils';
import {getClientId} from './meta/clientId';

const getPropertyIds = () => {
  const isSnapchatApp = navigator.userAgent.match(/snapchat/i);
  const isTiktokApp = navigator.userAgent.match(/BytedanceWebview/i);

  if (isSnapchatApp || isTiktokApp) {
    return {
      ga4Id: GA4_ID_SNAPCHAT,
      ga4IdNew: null,
      uaId: UA_ID_SNAPCHAT
    };
  }

  return {
    ga4Id: GA4_ID,
    ga4IdNew: GA4_NEW_ID,
    uaId: UA_ID
  };
};

// This needs to be like this
export const gtag = function () {
  // eslint-disable-next-line prefer-rest-params
  window.molFeDataLayer.push(arguments);
};

export const loadGtag = async () => {
  window.molFeDataLayer = window.molFeDataLayer || [];
  window.gtag = gtag;
  gtag('js', new Date());

  try {
    await loadScript(GTAG_SCRIPT_URL);
  } catch (error) {
    logger.error('Error loading gtag JS', error);
  }
};

export const setUserProperties = (props) => {
  gtag('set', 'user_properties', props);
};

const getConfigPromise = async () => {
  const {uaId, ga4Id, ga4IdNew} = getPropertyIds();
  const configProps = await getConfigProps();
  const props = toLowerCase(configProps, ['referrer_type']);

  const {
    environment
  } = getPageMetadata();
  const linkedDomains = environment === 'production' ?
    [
      'dailymail.co.uk',
      'thisismoney.co.uk',
      'mailsubscriptions.co.uk',
      'themailsubscriptions.co.uk',
      'mymailaccount.co.uk'
    ] :
    [
      'dailymailint.co.uk',
      'thisismoneyint.co.uk',
      'stagingmailplussubs.coderush.io',
      'uat-dlv.nonprod-mailsubscriptions.gcpp.io',
      'main-mma-preprod.coderush.io'
    ];

  gtag('config', uaId, {
    client_id: await getClientId(uaId),
    custom_map: CUSTOM_DIMENSION_MAP,
    linker: {
      accept_incoming: true,
      domains: linkedDomains
    },
    send_page_view: false,
    ...props
  });

  const {registration_id, puzzle_user_type, ...propsWithoutRegistrationId} = props;

  const ga4ClientId = await getClientId(ga4Id);

  gtag('config', ga4Id, {
    clientId: ga4ClientId,
    linker: {
      accept_incoming: true,
      domains: linkedDomains
    },
    send_page_view: false,
    user_properties: {
      client_id: ga4ClientId,
      puzzle_user_type,
      registration_id,
      registrationId: registration_id
    },
    ...propsWithoutRegistrationId
  });

  if (ga4IdNew) {
    gtag('config', ga4IdNew, {
      linker: {
        accept_incoming: true,
        domains: linkedDomains
      },
      send_page_view: false,
      user_properties: {
        client_id: await getClientId(ga4IdNew),
        puzzle_user_type,
        registration_id,
        registrationId: registration_id
      },
      ...propsWithoutRegistrationId
    });
  }

  setConfig('molFeGAProps', props);

  if (props.puzzle_user_type) {
    setUserProperties({
      puzzle_user_type: props.puzzleUserType,
      puzzleUserType: props.puzzleUserType
    });
  }
};

let configPromise = null;

const setConfigIfNeeded = () => {
  if (!configPromise) {
    configPromise = getConfigPromise();
  }

  return configPromise;
};

export const pageView = async (props) => {
  const {uaId, ga4Id, ga4IdNew} = getPropertyIds();

  await setConfigIfNeeded();
  gtag('event', 'page_view', {
    ...toLowerCase(props),
    send_to: ga4IdNew ? [uaId, ga4Id, ga4IdNew] : [uaId, ga4Id]
  });
};

const MAX_EVENT_NAME_LENGTH = 40;

export const event = async (action, props, ga4EventName, sendImmediately) => {
  const {uaId, ga4Id, ga4IdNew} = getPropertyIds();

  await setConfigIfNeeded();

  if (sendImmediately) {
    gtag('event', action, {
      ...toLowerCase(props),
      send_to: uaId
    });
  } else {
    setTimeout(() => {
      gtag('event', action, {
        ...toLowerCase(props),
        send_to: uaId
      });
    }, 500);
  }

  let extendedEventName = ga4EventName;

  if (!extendedEventName) {
    const eventNamePrefix = props && props.event_category || 'unknown';

    extendedEventName = `${eventNamePrefix}_${action}`.replace(/\s/g, '_');

    if (extendedEventName.length > MAX_EVENT_NAME_LENGTH) {
      extendedEventName = extendedEventName.substring(0, MAX_EVENT_NAME_LENGTH);
    }
  }

  // Handle GA4 event request
  if (extendedEventName) {
    if (sendImmediately) {
      gtag('event', extendedEventName, {
        ...toLowerCase(props),
        send_to: ga4IdNew ? [ga4Id, ga4IdNew] : ga4Id
      });
    } else {
      setTimeout(() => {
        gtag('event', extendedEventName, {
          ...toLowerCase(props),
          send_to: ga4IdNew ? [ga4Id, ga4IdNew] : ga4Id
        });
      }, 500);
    }
  }
};
