import {event} from '../gtag';

const createHandler = (category, action, nonInteraction, isBottomLink) => (_, data) => {
  const {company, headline} = data;

  // company - in case of click action
  // headline - in case of impression action

  event(action, {
    event_category: category,
    event_label: isBottomLink || action === 'impression' ? headline : company,
    non_interaction: Boolean(nonInteraction),
    type_of_tim_affiliate: isBottomLink || action === 'impression' ? headline : company,
    typeoftimaffiliate: isBottomLink || action === 'impression' ? headline : company
  });
};

export const timAffiliateHandlers = {
  TIM_AFFILIATE_BOTTOM_LINK_CLICKED: createHandler('timAffiliate', 'click', false, true),
  TIM_AFFILIATE_IMPRESSION: createHandler('timAffiliate', 'impression', true),
  TIM_AFFILIATE_LEARN_MORE_CLICKED: createHandler('timAffiliate', 'click', false)
};
