/* eslint-disable id-match */
import jsCookie from 'js-cookie';
import {event, pageView} from '../gtag';

const createHandler = (category, action, nonInteraction = false) => () => {
  event(action, {
    event_category: category,
    non_interaction: nonInteraction
  });
};

const handleMobileHomePageViewEvent = (_, data) => {
  const currentView = data.swipe || jsCookie.get('mol-home');
  const currentGeo = jsCookie.get('mol-home-default-channel');

  let chosenOverlay = 'Mobile Landing Homepage';

  switch (currentView) {
  case 'news':
    chosenOverlay = 'Just the News';
    break;
  case 'showbiz':
    chosenOverlay = 'Just the Showbiz';
    break;
  case 'desktop':
    chosenOverlay = 'Full Homepage';

    if (currentGeo) {
      chosenOverlay += ` ${currentGeo}-CA`;
    }
    break;
  }

  if (data.actionSource) {
    chosenOverlay += ` ${data.actionSource}`;
  }

  event('choice', {
    event_category: 'mobile homepage overlay',
    event_label: chosenOverlay,
    mobile_home_preference: chosenOverlay,
    mobileHomePreference: chosenOverlay,
    non_interaction: false,
    selected_value: chosenOverlay,
    selectedValue: chosenOverlay
  });
};

const handleMobileHomePageSwipeEvent = (_, data) => {
  const {action, label} = data;

  event('choice', {
    event_action: action,
    event_category: 'mobile homepage nav',
    event_label: label,
    non_interaction: false,
    selected_value: label,
    selectedValue: label
  }, `mobile_homepage_nav_choice_${label === 'news' ? 'news' : 'showbiz'}`);
};

const handleMobileHomePageNavEvent = (_, data) => {
  const {action, label} = data;

  event(action, {
    event_category: 'mobile homepage nav',
    event_label: label,
    non_interaction: false,
    selected_value: label,
    selectedValue: label
  },
  data.action === 'bell icon click' && 'mobile_bell_icon_click'
  );

  if (action === 'bell icon click') {
    pageView({
      mobileHomeTabActive: 'alerts-overlay'
    });
  }
};

export const pageOverlayHandlers = {
  DESKTOP_HOME_PAGE_OVERLAY: createHandler('desktop homepage overlay', 'display', true),
  MOBILE_HOME_PAGE_NAV: handleMobileHomePageNavEvent,
  MOBILE_HOME_PAGE_OVERLAY: createHandler('mobile homepage overlay', 'display', true),
  MOBILE_HOME_PAGE_SIGNPOST_CLICK: createHandler('mobile homepage signpost', 'click'),
  MOBILE_HOME_PAGE_SIGNPOST_DISMISS: createHandler('mobile homepage signpost', 'dismiss'),
  MOBILE_HOME_PAGE_SIGNPOST_DISPLAY: createHandler('mobile homepage signpost', 'display', true),
  MOBILE_HOME_PAGE_SWIPE: handleMobileHomePageSwipeEvent,
  MOBILE_HOME_PAGE_VIEW: handleMobileHomePageViewEvent
};
