/* eslint-disable id-match */
import {getPuzzleUserType} from '../../puzzle/session';
import {event, setUserProperties} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  setUserProperties({
    puzzle_user_type: getPuzzleUserType(),
    puzzleUserType: getPuzzleUserType()
  });

  event(action, {
    event_category: category,
    event_label: data && data.puzzleName,
    non_interaction: Boolean(nonInteraction),
    puzzle_name: data && data.puzzleName,
    puzzle_user_type: getPuzzleUserType(),
    puzzleName: data && data.puzzleName,
    puzzleUserType: getPuzzleUserType()
  });
};

export const puzzleHandlers = {
  PUZZLE_COMPLETE: createHandler('puzzle', 'complete'),
  PUZZLE_STARTED: createHandler('puzzle', 'started')
};
