/* eslint-disable id-match */
import {event} from '../gtag';

const createHandler = (category, action, nonInteraction) => (_, data) => {
  const {pollId, question, answer, href, channel} = data || {};

  event(action, {
    answer,
    channel,
    event_category: category,
    event_label: category === 'newsletter' ? 'poll' : question,
    href,
    non_interaction: Boolean(nonInteraction),
    poll_id: pollId ? String(pollId) : pollId,
    poll_name: question,
    pollId: pollId ? String(pollId) : pollId,
    pollName: question,
    url_channel: channel
  }, category === 'newsletter' ? 'newsletter_poll_submit_success' : undefined);
};

export const xpPollsHandlers = {
  XP_POLLS_CLICK: createHandler('poll', 'click'),
  XP_POLLS_IMPRESSION: createHandler('poll', 'impression', true),
  XP_POLLS_SUBSCRIBED: createHandler('newsletter', 'successful submits'),
  XP_POLLS_VIEW: createHandler('poll', 'view', true),
  XP_POLLS_VOTED: createHandler('poll', 'submit success')
};
